import {
	Carousel,
	Heading,
	Link,
	LinkButton,
	Picture,
	Container,
	HorizontalRule,
	Button,
	Section,
	Page,
} from '@troon/ui';
import { For, Show } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { createAsync } from '@solidjs/router';
import { Meta, Title } from '@solidjs/meta';
import { IconChevronRight } from '@troon/icons/chevron-right';
import { HeroVideo } from '../../../components/hero/video';
import { TeeTimeSearch } from '../../../components/tee-time-search';
import { getConfigValue } from '../../../modules/config';
import { gql } from '../../../graphql';
import { cachedQuery } from '../../../graphql/cached-get';
import { UpsellAccess } from '../../../components/upsells/access';
import { VideoSection } from '../../../components/layouts/video-section';
import { getSchemaString } from '../../../modules/schema/schema';
import { image } from '../../../modules/schema/image';
import { webpage } from '../../../modules/schema/webpage';
import { PopularDestination } from './_components/popular-destination';
import type { RouteDefinition } from '@solidjs/router';
import type { JSX } from 'solid-js';
import type { FacilityCardFragment } from '../../../graphql';

export function LoggedOut() {
	const data = createAsync(() => getHomeData({}));

	return (
		<>
			<Title>Troon | Book tee times and earn rewards at Troon golf courses</Title>
			<Meta
				name="description"
				content="Troon Rewards is the best way to book tee times at Troon courses around the world. Find exclusive rates and tee times. Earn points towards complimentary rounds of golf. Sign up today!"
			/>
			<Meta name="og:image" content={`${getConfigValue('IMAGE_HOST')}/web/photo/og/home-image.jpg`} />

			<Show when={data()?.featuredFacilities}>
				{(facilities) => (
					<script
						type="application/ld+json"
						innerText={getSchemaString([
							...((facilities() as Array<FacilityCardFragment>)?.map((facility) =>
								image(facility.metadata!.hero!.url!),
							) ?? []),
							webpage('/', {
								title: 'Book tee times at your favorite golf courses | Troon Rewards',
							}),
						])}
					/>
				)}
			</Show>

			<HeroVideo>
				<Container class="mb-20 flex flex-col gap-4 self-end">
					<Heading as="h1" class="capitalize lg:text-6xl">
						Your next round awaits
					</Heading>
					<p class="md:text-lg">Explore the world of Troon and find the perfect course for your next round.</p>
				</Container>
			</HeroVideo>

			<Page>
				<Container class="relative z-30 -mt-32 md:-mt-24">
					<div class="rounded bg-white p-4 shadow-lg">
						<TeeTimeSearch onSubmit={() => {}} />
					</div>
				</Container>

				<Section>
					<Container>
						<NoHydration>
							<div class="flex flex-col gap-8">
								<Heading as="h2">Experience the World of Troon®</Heading>

								<div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
									<For each={experiences}>
										{(data) => (
											<article class="group relative flex shrink grow basis-full flex-col gap-4">
												<div class="overflow-hidden rounded">
													<Picture
														src={data.image}
														width={400}
														height={300}
														sizes="(min-width: 1024px) 33vw, 95vw"
														alt=""
														// eslint-disable-next-line tailwindcss/no-arbitrary-value
														class="pointer-events-none aspect-[4/3] w-full object-cover group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
														loading="lazy"
													/>
												</div>
												<div class="flex flex-col gap-1">
													<Heading as="h3" size="h5">
														{data.title}
													</Heading>
													<p class="text-sm">{data.content}</p>
												</div>
												<LinkButton
													href={data.url}
													class="-ms-2 flex w-fit flex-row gap-2 after:absolute after:inset-0 after:z-0"
												>
													{data.linkText}
													<IconChevronRight />
												</LinkButton>
											</article>
										)}
									</For>
								</div>
							</div>
						</NoHydration>
					</Container>
				</Section>

				<Container>
					<UpsellAccess location="logged-out home" />
				</Container>

				<Section>
					<Container>
						<Heading as="h2">Featured Courses</Heading>
					</Container>

					<Carousel pixelsPerFrame={0.5}>
						<For each={data()?.featuredFacilities}>
							{(fac) => (
								<Carousel.Item>
									<div class="pe-3 md:pe-4">
										<div
											// eslint-disable-next-line tailwindcss/no-arbitrary-value
											class="group relative flex aspect-[2/3] max-h-[45vh] w-full flex-col items-start justify-end overflow-hidden rounded p-4 xl:p-8"
										>
											<Show when={fac.metadata?.hero?.url}>
												{(hero) => (
													<Picture
														draggable={false}
														src={hero()}
														width={400}
														height={600}
														sizes="25vw"
														class="absolute inset-0 z-0 size-full object-cover group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
														loading="lazy"
													/>
												)}
											</Show>
											<div class="absolute inset-0 z-10 bg-gradient-to-b from-black/0 via-black/0 to-black/80" />
											<Link
												draggable={false}
												href={`/course/${fac.slug}`}
												class="z-10 text-lg font-semibold text-white after:absolute after:inset-0 after:z-10 md:text-xl"
											>
												{fac.name}
											</Link>
											<p class="z-10 text-sm text-white md:text-base">
												{fac.metadata?.address?.city}, {fac.metadata?.address?.state}
											</p>
										</div>
									</div>
								</Carousel.Item>
							)}
						</For>
					</Carousel>
				</Section>

				<Container>
					<Section>
						<Heading as="h2">Popular Destinations</Heading>

						<ul class="grid grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4">
							<For each={data()?.featuredDestinations}>
								{(dest) => (
									<li>
										<PopularDestination {...dest} />
									</li>
								)}
							</For>
						</ul>
					</Section>
				</Container>

				<Container>
					<HorizontalRule />
				</Container>

				<Container>
					<VideoSection
						src={`${getConfigValue('CDN')}/web/video/management-services/overview/index.m3u8`}
						poster={`${getConfigValue('IMAGE_HOST')}/web/video/troon-approach-20240926.jpg`}
					>
						<Heading as="h2">World-class golf and club management services.</Heading>
						<p class="mb-4">
							Troon® started as one facility in 1990 and has since grown to become the world’s largest professional
							club management company. Today, we provide services to 900+ locations and manage various amenities, such
							as racquet sports, aquatics, fitness, food & beverage, lodging, homeowners associations, and more.
						</p>
						<Button class="w-fit grow-0" as={Link} href="/management-services">
							Learn more<span class="sr-only"> about Troon’s management services</span>
						</Button>
					</VideoSection>
				</Container>
			</Page>
		</>
	);
}

const experiences: Array<{ image: string; title: string; content: string; url: string; linkText: JSX.Element }> = [
	{
		image: `${getConfigValue('IMAGE_HOST')}/web/hero/play-troon-2.jpg`,
		title: 'Play Troon',
		content:
			'It has never been easier to play your favorite Troon courses. Compare rates and availability across our network.',
		url: '/tee-times',
		linkText: 'Book a tee time',
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/web/hero/tennis-experiences.jpg`,
		title: 'Troon Privé',
		content:
			'Members of Troon Privé Clubs receive exclusive access to extraordinary golf and lifestyle benefits throughout the world.',
		url: '/member-programs',
		linkText: (
			<>
				Learn more<span class="sr-only"> about Troon Privé</span>
			</>
		),
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/web/hero/career.jpeg`,
		title: 'Management Services',
		content: 'See why Troon is the #1 golf and club-related hospitality management company in the world.',
		url: '/management-services',
		linkText: (
			<>
				Learn more<span class="sr-only"> about Troon’s Management Services</span>
			</>
		),
	},
];

const homeQuery = gql(`
query loggedOutHome {
	featuredFacilities: facilities(featured: true, limit: 12) {
		slug
		name
		metadata {
			hero {
				url
			}
			address {
				city
				state
			}
		}
	}
	featuredDestinations {
		... on DestinationRegion {
			...PopularRegion
		}
		... on DestinationPlace {
			...PopularPlace
		}
	}
}`);

const getHomeData = cachedQuery(homeQuery);

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
